<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Inicio</span>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row><v-col> </v-col></v-row>
    <v-row>
      <!-- <v-col md="3">
        <v-card>
          <v-row>
            <v-col cols="12">
              <v-card-title> Próximos Feriados 85 </v-card-title>
              <v-card-subtitle> En los próximos 30 días </v-card-subtitle>
            </v-col>
            <v-spacer> </v-spacer>
          </v-row>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                Día del marmotón
                <br />
                Jueves Santo
                <br />
                Viernes Santo
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col md="3"> </v-col>
      <v-col md="3"> </v-col>
      <v-col md="3"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Inicio",
  components: {},
  data() {
    return {
      ingresos: null,
      empleados: [],
      pageNumber: "1",
      loading: false,
    };
  },
  methods: {
    sendempleados(empleados) {
      // this.pageNumber = "1";
      this.empleados = empleados;
      this.$refs.personaTable.loading = false;
    },

    handlePagination(pageNumber) {
      //console.log("paginate to: " + pageNumber);
      this.pageNumber = pageNumber;
    },
  },
};
</script>
